import { createStore } from 'vuex';
import swat from './modules/swat';
import notificationMessage from './modules/notificationMessage';

export default createStore({
  modules: {
    swat,
    notificationMessage
  },
  strict: false // don't enable, bad performance
});
