
import _find from 'lodash/find';

const state = { messages: [] };

const mutations = {
  ADD_MESSAGE(state, message) {
    state.messages.push(message);
  },
  REMOVE_MESSAGE(state, message) {
    state.messages.splice(state.messages.indexOf(message), 1);
  },
  UPDATE_MESSAGE(state, newMessage) {

    const msg = _find(state.messages, { 'id': newMessage.id });

    Object.assign(msg, newMessage);
  }
};

const actions = {
  addMessage: (store, message) => {
    store.commit('ADD_MESSAGE', message);
  },
  removeMessage: (store, message) => {
    store.commit('REMOVE_MESSAGE', message);
  },
  removeMessageById: (store, id) => {
    for (const childMsg of store.state.messages) {
      if (childMsg.id == id)
        store.commit('REMOVE_MESSAGE', childMsg);
    }
  },
  updateMessage: (store, message) => {
    store.commit('UPDATE_MESSAGE', message);
  }
};

const getters = {
  getMessageById: state => id => {
    for (const childMsg of state.messages) {
      if (childMsg.id == id)
        return childMsg;
    }
  },
  messages(state) {
    return state.messages;
  },
  text: state => id => {
    for (const childMsg of state.messages) {
      if (childMsg.id == id)
        return childMsg.text;
    }
  },
  type: state => id => {
    for (const childMsg of state.messages) {
      if (childMsg.id == id)
        return childMsg.type;
    }
  },
  timeout: state => id => {
    for (const childMsg of state.messages) {
      if (childMsg.id == id)
        return childMsg.timeout;
    }
  },
  hidden: state => id => {
    for (const childMsg of state.messages) {
      if (childMsg.id == id)
        return childMsg.hidden;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
