import store from './store';

import common from './store/modules/swat/screens/common/common.js';
import taskbar from './controls/Taskbar/taskbar.module';
import form from './store/modules/swat/screens/common/form.js';
import grid from './store/modules/swat/screens/visual/panel/fieldcontainer/grid/grid.js';
import datasource from './store/modules/swat/screens/data/datasource.js';
import sidebar from './controls/Sidebar/sidebar.module';
import notificationMessage from './store/modules/notificationMessage.js';
import * as Vue from 'vue';

/**
 * Method for defining Vue global variables
 */
const defineVueGlobals = () => {
  if (!global.akioma)
    global.akioma = {};
  global.akioma.VuexStore = store;
  global.akioma.VuexModules = {};
  global.Vue = Vue;
  global.akioma.VuexModules.commonModule = common;
  global.akioma.VuexModules.taskbarModule = taskbar;
  global.akioma.VuexModules.notificationMessageModule = notificationMessage;
  global.akioma.VuexModules.formModule = form;
  global.akioma.VuexModules.gridModule = grid;
  global.akioma.VuexModules.dataSourceModule = datasource;
  global.akioma.VuexModules.sidebarModule = sidebar;
};
defineVueGlobals();

/**
 * Method for globally loading the InstancesFactory class
 * @returns Promise
 */
global.akioma.loadVueInstancesFactory = () => new Promise(resolve => {
  import('./InstancesFactory').then(res => {
    const InstancesFactory = res.default;
    global.akioma.VueInstancesFactory = InstancesFactory;
    resolve();
  });
});


