

import common from '../../store/modules/swat/screens/common/common.js';

const state = () => ({
  ...common.state(),
  items: [],
  menuId: null
});

const mutations = {
  ...common.mutations,
  SET_MENU(state, menuId) {
    state.menuId = menuId;
  },
  SET_ENABLED(state, enabled) {
    state.enabled = enabled;
  }
};

const actions = { ...common.actions };

const getters = { ...common.getters };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};


